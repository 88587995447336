.bg-primary {
    background-color: $primary !important;
}
.bg-secondary {
    background-color: $secondary !important;
}
.bg-info {
    background-color: $info !important;
}
.bg-success {
    background-color: $success !important;
}
.bg-warning {
    background-color: $warning !important;
}
.bg-danger {
    background-color: $danger !important;
}
.bg-dark {
    background-color: $dark !important;
}
.bg-light {
    background-color: $light !important;
}
.bg-white {
    background-color: $white !important;
}
.bg-pink {
    background-color: $pink !important;
}
.bg-purple {
    background-color: $purple !important;
}
.bg-teal {
    background-color: $teal !important;
}
.bg-blue {
    background-color: $blue !important;
}

.bg-primary {
    background-color: $primary !important;
    &.bg-opacity-10 {
        background-color: rgba($primary,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($primary,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($primary,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($primary,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($primary,1) !important;
    }
}
.bg-secondary {
    background-color: $secondary !important;
    &.bg-opacity-10 {
        background-color: rgba($secondary,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($secondary,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($secondary,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($secondary,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($secondary,1) !important;
    }
}
.bg-info {
    background-color: $info !important;
    &.bg-opacity-10 {
        background-color: rgba($info,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($info,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($info,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($info,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($info,1) !important;
    }
}
.bg-success {
    background-color: $success !important;
    &.bg-opacity-10 {
        background-color: rgba($success,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($success,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($success,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($success,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($success,1) !important;
    }
}
.bg-warning {
    background-color: $warning !important;
    &.bg-opacity-10 {
        background-color: rgba($warning,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($warning,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($warning,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($warning,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($warning,1) !important;
    }
}
.bg-danger {
    background-color: $danger !important;
    &.bg-opacity-10 {
        background-color: rgba($danger,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($danger,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($danger,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($danger,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($danger,1) !important;
    }
}
.bg-dark {
    background-color: $dark !important;
    &.bg-opacity-10 {
        background-color: rgba($dark,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($dark,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($dark,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($dark,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($dark,1) !important;
    }
}
.bg-light {
    background-color: $light !important;
    &.bg-opacity-10 {
        background-color: rgba($light,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($light,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($light,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($light,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($light,1) !important;
    }
}
@each $color,
$value in $theme-colors {
    .bg-#{$color} {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: $white;
    }
}
.bg-soft-primary {
    background-color: $primary-300;
    color: $primary;
}
.bg-soft-secondary {
    background-color: $secondary-300;
    color: $secondary;
}
.bg-soft-success {
    background-color: $success-300;
    color: $success;
}
.bg-soft-danger {
    background-color: $danger-300;
    color: $danger;
}
.bg-soft-warning {
    background-color: $warning-300;
    color: $warning;
}
.bg-soft-info {
    background-color: rgba($info, 0.3);
    color: $info;
}
.bg-soft-light {
    background-color: rgba($light, 0.9);
    color: $text-color;
}
.bg-soft-dark {
    background-color: rgba($dark, 0.3);
    color: $white;
}
bg-light {
  color: $text-color;
}

.bg-primary-gradient {
  background: $primary-gradient !important;
  color: $white;
}
.bg-secondary-gradient {
  background: $secondary-gradient !important;
  color: $white;
}
.bg-warning-gradient {
  background: $warning-gradient !important;
  color: $white;
}
.bg-info-gradient {
  background: $info-gradient !important;
  color: $white;
}
.bg-success-gradient {
  background: $success-gradient !important;
  color: $white;
}
.bg-danger-gradient {
  background: $danger-gradient !important;
  color: $white;
}
.bg-orange-gradient {
  background: $orange-gradient !important;
  color: $white;
}
.bg-purple-gradient {
  background: $purple-gradient !important;
  color: $white;
}
.bg-teal-gradient {
  background: $teal-gradient !important;
  color: $white;
}
.bg-light-gradient {
  background: $light-gradient !important;
  color: $black;
}
.bg-dark-gradient {
  background: $dark-gradient !important;
  color: $white;
}
.bg-outline-primary {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
}
.bg-outline-secondary {
  background-color: $white;
  border: 1px solid $secondary;
  color: $secondary;
}
.bg-outline-warning {
  background-color: $white;
  border: 1px solid $warning;
  color: $warning;
}
.bg-outline-info {
  background-color: $white;
  border: 1px solid $info;
  color: $info;
}
.bg-outline-success {
  background-color: $white;
  border: 1px solid $success;
  color: $success;
}
.bg-outline-danger {
  background-color: $white;
  border: 1px solid $danger;
  color: $danger;
}
.bg-outline-dark {
  background-color: $white;
  border: 1px solid $dark;
  color: $dark;
}
.bg-outline-light {
  background-color: $white;
  border: 1px solid $light !important;
  color: $black !important;
}
.bg-primary-transparent {
    background-color: rgba($primary, 0.1) !important;
    color: $primary !important;
    &:hover {
        background-color: rgba($primary, 0.1) !important;
        color: $primary !important;
    }
}
.bg-secondary-transparent {
    background-color: rgba($secondary, 0.1) !important;
    color: $secondary !important;
    &:hover {
        background-color: rgba($secondary, 0.1) !important;
        color: $secondary !important;
    }
}
.bg-info-transparent {
    background-color: rgba($info, 0.1) !important;
    color: $info !important;
    &:hover {
        background-color: rgba($info, 0.1) !important;
        color: $info !important;
    }
}
.bg-success-transparent {
    background-color: rgba($success, 0.1) !important;
    color: $success !important;
    &:hover {
        background-color: rgba($success, 0.1) !important;
        color: $success !important;
    }
}
.bg-warning-transparent {
    background-color: rgba($warning, 0.1) !important;
    color: $warning !important;
    &:hover {
        background-color: rgba($warning, 0.1) !important;
        color: $warning !important;
    }
}
.bg-danger-transparent {
    background-color: rgba($danger, 0.1) !important;
    color: $danger !important;
    &:hover {
        background-color: rgba($danger, 0.1) !important;
        color: $danger !important;
    }
}
.bg-light-transparent {
    background-color: rgba($light, 0.5) !important;
    color: $light !important;
    &:hover {
        background-color: rgba($light, 0.5) !important;
        color: $text-color !important;
    }
}
.bg-gray-100 {
    background-color: $gray-100; 
}
.bg-gray-200 {
    background-color: $gray-200;
}
.bg-gray-300 {
    background-color: $gray-300;
}
.bg-gray-400 {
    background-color: $gray-400;
}
.bg-gray-500 {
    background-color: $gray-500;
}
.bg-gray-600 {
    background-color: $gray-600;
}
.bg-gray-700 {
    background-color: $gray-700;
}
.bg-gray-800 {
    background-color: $gray-800;
}
.bg-gray-900 {
    background-color: $gray-900;
}
.svg-primary {
    fill: $primary;
}
.svg-secondary {
    fill: $secondary;
}
.svg-success {
    fill: $success;
}
.svg-danger {
    fill: $danger;
}
.svg-warning {
    fill: $warning;
}
.svg-white {
    fill: $white;
}
.svg-black {
    fill: $black;
}
.svg-pink {
    fill: $pink;
}
.svg-orange {
    fill: $primary;
}
.svg-purple {
    fill: $purple;
}
.svg-indigo {
    fill: $indigo;
}
.svg-info {
    fill: $info;
}
.svg-yellow {
    fill: $yellow;
}
.svg-light {
  fill: $light;
}
.svg-dark {
  fill: $dark;
}
.svg-teal {
  fill: $teal;
}
.svg-default {
  fill: $text-color;
}
.color-container {
  width: 5rem;
  height: 5rem;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
}
.text-container {
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
.color-box {
    width: 80px;
    height: 80px;
    border-radius: $border-radius;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
}
