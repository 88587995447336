body {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    /* Setting the default font size */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'IBM Plex Sans', sans-serif;
}

.table thead th {
    background-color: #95312F;
    /* Dark background color */
    color: white;
    /* White text color */
}

.table th,
.table td {
    font-size: 0.875rem;
    /* Ensure table text is also the correct size */
}

.disappear {
    display: none;
}

.custom-modal {
    max-width: 90%;
    /* Adjust the width as needed */
    width: 900px;
    /* Set a specific width */
    max-height: 90vh;
    /* Maximum height of the modal */
    height: 800px;
    /* Set a specific height */
    overflow-y: auto;
    /* Enable vertical scrolling if content exceeds height */
}